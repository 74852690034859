import React from "react";
import _ from "lodash";

import { Layout } from "../components/index";
import { markdownify, getPages, Link, safePrefix } from "../utils";

export default class Posts extends React.Component {
  render() {
    const lang = _.get(this.props, "pageContext.frontmatter.lang");
    let productsPath = "/gamme";
    if (lang === "en") {
      productsPath = "/en/products";
    }
    if (lang === "es") {
      productsPath = "/es/productos";
    }
    return (
      <Layout {...this.props} lang={lang}>
        <section id="main" className="wrapper">
          <div className="inner">
            <header className="major">
              <h1>{_.get(this.props, "pageContext.frontmatter.title")}</h1>
              {markdownify(
                _.get(this.props, "pageContext.frontmatter.subtitle")
              )}
            </header>
            <div className="posts">
              {_.map(
                _.orderBy(
                  getPages(this.props.pageContext.pages, productsPath),
                  "frontmatter.banner.title",
                  "asc"
                ),
                (product, product_idx) => (
                  <section
                    key={product_idx}
                    className="card"
                    style={{
                      "--nb-col": _.get(
                        this.props,
                        "pageContext.frontmatter.columns"
                      ),
                    }}
                  >
                    {_.get(product, "frontmatter.alt_img") && (
                      <Link
                        to={safePrefix(_.get(product, "url"))}
                        className="image"
                      >
                        <img
                          src={safePrefix(
                            _.get(product, "frontmatter.alt_img")
                          )}
                          alt=""
                        />
                      </Link>
                    )}
                    <div className="content">
                      <h3>{_.get(product, "frontmatter.banner.title")}</h3>
                      <div className="excerpt">
                        {markdownify(_.get(product, "frontmatter.excerpt"))}
                      </div>
                      <Link
                        to={safePrefix(_.get(product, "url"))}
                        className="button"
                      >
                        {_.get(this.props, "pageContext.frontmatter.ctaLabel")}
                      </Link>
                    </div>
                  </section>
                )
              )}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
